import Web3 from 'web3'
// import { ethers } from "ethers";
// import { EthereumProvider } from '@walletconnect/ethereum-provider'
import detectEthereumProvider from '@metamask/detect-provider';
import { CHAIN_LIST } from '../constants/chains';
import { DAPP_WALLET_LIST } from '../constants';

let web3Instance = null;

const generateDappUrl = ({ link, name }) => {
  if (link) {
    if (name === 'Metamask') {
      const dappUrl = `${window.location.href}`.trim();
      if (dappUrl.search('https://') !== -1) {
        const pageUrl = `${link}/dapp/${dappUrl.replace('https://', '')}`;
        window.location.href = pageUrl;
      }
    }
  }
};

export const openMetamaskBrowser = () => generateDappUrl(DAPP_WALLET_LIST[0]);

export const getWeb3Instance = async () => {
  if (web3Instance) return web3Instance;
  const provider = await detectEthereumProvider();
  if (provider) {
    web3Instance = new Web3(provider)
    if (provider !== window.ethereum) {
      console.error('Do you have multiple wallets installed?');
    }
    return web3Instance;
  } else {
    alert('Please install MetaMask!');
  }
  return null;
}

export const sign = async (publicAddress, message, provider) => {
  const web3 = provider ? new Web3(provider) : await getWeb3Instance();
  if (!web3) return;
  const signature = await web3.eth.personal.sign(
    message,
    publicAddress,
    ''
  )
  return signature
}

// check network corresponding to product
export const checkCorrespondingNetwork = async chainId => {
  const web3 = await getWeb3Instance();
  try {
    const localChainId = await web3.eth.getChainId();
    if (localChainId === chainId) {
      return true;
    }

    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      // params: [{ chainId: `0x${CHAIN_LIST[productChainId]?.chainId.toString(16)}` }],
      params: [{ chainId: `0x${chainId.toString(16)}` }],
    });

    return true;
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (error.code === 4902) {
      const networkData = {
        chainId: `0x${CHAIN_LIST[chainId]?.chainId.toString(16)}`,
        chainName: CHAIN_LIST[chainId]?.displayName,
        rpcUrls: CHAIN_LIST[chainId]?.rpcUrls,
        blockExplorerUrls: CHAIN_LIST[chainId]?.blockExplorerUrls,
        nativeCurrency: {
          name: CHAIN_LIST[chainId]?.currency,
          symbol: CHAIN_LIST[chainId]?.symbol,
          decimals: CHAIN_LIST[chainId]?.decimals,
        },
      };

      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [networkData],
      });

      const nextCurrentChainId = await web3.eth.getChainId();

      if (+nextCurrentChainId === +chainId) {
        return true;
      }

      // eslint-disable-next-line no-throw-literal
      throw { error: 'network_not_corresponding' };
    }

    throw error;
  }
};

// window.ethereum.on('chainChanged', handleChainChanged);

// function handleChainChanged(chainId) {
//   window.location.reload();
// }

// /***********************************************************/
// /* Handle user accounts and accountsChanged (per EIP-1193) */
// /***********************************************************/

// let currentAccount = null;
// window.ethereum.request({ method: 'eth_accounts' })
//   .then(handleAccountsChanged)
//   .catch((err) => {
//     console.error(err);
//   });

// window.ethereum.on('accountsChanged', handleAccountsChanged);

// function handleAccountsChanged(accounts) {
//   if (accounts.length === 0) {
//     console.log('Please connect to MetaMask.');
//   } else if (accounts[0] !== currentAccount) {
//     currentAccount = accounts[0];
//   }
// }

// async function getAccount() {
//   const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
//     .catch((err) => {
//       if (err.code === 4001) {
//         console.log('Please connect to MetaMask.');
//       } else {
//         console.error(err);
//       }
//     });
//   return accounts[0];
// }
