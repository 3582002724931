import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, notification } from 'antd';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useAppDispatch } from '../../stores';
import { verifyEmailAction } from '../../stores/screens/auth/auth.action';
import { styleVisibleModal } from '../../constants';

import { Images } from '../../theme';
import ButtonImage from '../button-img';
import FormControl from '../form-control';
import MaskLoading from '../mask-loading';
import Confirmable from '../confirmable';

import { ModalVerifyEmail, ModalVerifyEmailStyled } from './styled';

export const ModalContext = React.createContext();

export const ModalVerifyEmailProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState();

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          hide: () => setIsOpen(false),
          open: () => setIsOpen(true),
        }),
        []
      )}
    >
      {isOpen ? <ModalInvalidEmail setIsOpen={setIsOpen} /> : null}
      {children}
    </ModalContext.Provider>
  );
};

const schema = Yup.object().shape({
  email: Yup.string()
            .required('This field is required')
            .email('Email invalid')
            .max(70, 'Email are limited to 70 characters in length'),
});

export const ModalInvalidEmail = ({ setIsOpen }) => {
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    trigger();
    if (isValid) {
      requestVerifyEmail(data);
    }
  };

  const requestVerifyEmail = async (data) => {
    MaskLoading.open({});

    const { payload } = await dispatch(verifyEmailAction(data));
    if (payload?.status && payload?.data.success) {
      localStorage.clear();
      setIsOpen(false);

      Confirmable.open({
        content: (
          <ModalVerifyEmail>
            <div className='icon'>
              <img src={Images.ICON_EMAIL} alt='icon-mail' />
            </div>
            <div className='title'>Verify your email</div>
            <div className='content-verify'>
              We’ve sent an email to {data.email} to verify your email address
              and active your account. The link in the email expire in 24 hours.
            </div>
          </ModalVerifyEmail>
        ),
        hideCancelButton: true,
        hideOkButton: true,
        width: 650,
      });
    } else {
      setIsOpen(true);
      if (payload?.error === 'BAD_REQUEST') {
        notification.error({
          message: `Email ${data.email} already exists.`,
          duration: 5,
        });
      }
    }
    MaskLoading.close();
  };

  return (
    <>
      <ModalVerifyEmailStyled
        open={true}
        width={450}
        footer={null}
        destroyOnClose
        closable={false}
        maskClosable={false}
        style={styleVisibleModal}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='title'>Complete your profile</div>
          <FormControl>
            <label htmlFor='email'>
              <div className='sub-title'>
                To fully enjoy all the features of the game, we require you to
                provide email for your user profile.
              </div>
            </label>
            <Controller
              name='email'
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  id='email'
                  name='email'
                  placeholder='Email'
                  onChange={onChange}
                  value={value || ''}
                ></Input>
              )}
            />
            {errors?.email && <p className='error'>{errors.email.message}</p>}
          </FormControl>
          <div className='action-box'>
            <ButtonImage
              onClick={() => onSubmit(getValues())}
              src={Images.GENERATE_BTN}
              height={50}
              className='btn-vr'
            >
              Submit
            </ButtonImage>
          </div>
        </Form>
      </ModalVerifyEmailStyled>
    </>
  );
};
